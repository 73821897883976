(function($) {
  /* Breakpoints */
  var breakpoints = {large: 1200, medium: 992, small: 768 , xsmall: 767, nav: 992 };



  /* Check window width */
  var resizeTimeout = null;
  var cachedWindowWidth = $(window).width();

  // Check if window width has actually changed
  function checkWindowWidth() {
    var newWindowWidth = $(window).width();

    if (newWindowWidth !== cachedWindowWidth) {
      // If changed call functions on resize
      cachedWindowWidth = newWindowWidth;
    }
  }
  $(window).on('resize', function() {
    if (resizeTimeout !== null) {
      clearTimeout(resizeTimeout);
    }

    resizeTimeout = setTimeout(checkWindowWidth, 50);
  });



  /* Fix ADA errors/issues */
  // function adaFixes() {
  // }
  // adaFixes();



  /* Format phone numbers */
  // Strip non-numeric characters from phone numbers and add to href if element contains `tel` class
  function formatPhoneNumbers() {
    var phoneNumber;
    var phoneNumbers = $('.tel');
    var extension = $('.tel + .ext');

    phoneNumbers.each(function() {
      var $this;

      // Remove '1-' prefix before formatting if it exists
      if ($(this).text().substr(0, 2) === '1-') {
        $this = $(this).text().replace('1-', '');
      } else {
        $this = $(this).text();
      }

      // Set phone number, considering extension if it exists
      if (extension.length) {
        phoneNumber = 'tel:1' + $this.replace(/\D/g, '') + ';ext=' + extension.data('ext');
      } else {
        phoneNumber = 'tel:1' + $this.replace(/\D/g, '');
      }

      $(this).attr('href', phoneNumber);
    });
  }
  formatPhoneNumbers();



  /* Format email addresses */
  // Add email addresses to href if element contains `eml` class
  function formatEmailAddresses() {
    var emailAddress;
    var emailAddresses = $('.eml');

    emailAddresses.each(function() {
      emailAddress = 'mailto:' + $(this).text();
      $(this).attr('href', emailAddress);
    });
  }
  formatEmailAddresses();



  /* Header */
  /* Sticky Navigation */
  function stickyNavigation() {
    var didScroll;
    var lastScrollTop = 0;
    var fullHeader = $('.site-header');
    var fullHeaderClasses = fullHeader.attr('class');
    var mainHeader = $('.site-header > .main');
    // var mainHeaderClasses = mainHeader.attr('class');
    var fullHeaderHeight = fullHeader.outerHeight() + 2;
    var mainHeaderHeight = mainHeader.outerHeight();
    var topHeaderHeight = $('.site-header > .top').outerHeight();
    var delta = fullHeaderHeight / 2;

    // Add padding to entry header so the header doesn't cover it
    var mainSiteEntryHeader = $('.site-main > .entry-header');
    mainSiteEntryHeader.css('padding-top', fullHeaderHeight + 'px');

    // Detect scroll
    $(window).scroll(function(e) {
      didScroll = true;
    });

    setInterval(function() {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 100);

    function hasScrolled() {
      var st = $(this).scrollTop();

      // Make sure they scroll more than delta
      if (Math.abs(lastScrollTop - st) <= delta) {
        return;
      }

      if (st > lastScrollTop && st > mainHeaderHeight) {
        // Scroll Down
        fullHeader.addClass('sticky');
        $('body').addClass('sticky-active');
        fullHeader.css({
          'transform': 'translateY(-'+ (fullHeaderHeight + 12) + 'px)'
        });
      } else if (st < delta) {
        // Back at top
        fullHeader.removeClass('sticky');
        fullHeader.addClass(fullHeaderClasses);
        fullHeader.css({ 'transform': '' });

      } else if (st < lastScrollTop) {
        // Scroll Up
        if (st + $(window).height() < $(document).height()) {
          fullHeader.css({
            'transform': 'translateY(-' + (topHeaderHeight) + 'px)'
          });
        }
      }

      lastScrollTop = st;
    }
  }
  if (!$('.site-header').hasClass('not-sticky')) {
    stickyNavigation();
  }



  /* Desktop Navigation */
  // Handle large devices that show the desktop menu and need touch support as hover doesn't work (iPad Pro for example)
  function isTouchDevice(){
    return typeof window.ontouchstart !== 'undefined';
  }

  if (isTouchDevice() && (cachedWindowWidth >= breakpoints.nav)) {
    var mainNavTopLevelLinks = $('.site-header .main .navigation #menu-primary > .menu-item > a');
    mainNavTopLevelLinks.click(function(e) {
      mainNavTopLevelLinks.not(this).removeClass('clicked');
      $(this).toggleClass('clicked');
      if ($(this).hasClass('clicked')) {
        e.preventDefault();
      }
    });
  }



  /* Mobile Navigation */
  $('.mobile-nav-btn, .mobile-nav-overlay').on('click', function() {
    $('body').toggleClass('show-menu');
    $('.mobile-nav-btn').toggleClass('is-open');
    $('.menu-item-has-children').removeClass('show-sub-menu');
  });

  // Sub menus
  $('.site-header .main .navigation .menu-item-has-children > a').on('click', function(e) {
    if ($(window).width() < breakpoints.nav) {
      e.stopPropagation();
      e.preventDefault();

      var parentMenu = $(this)[0].outerHTML;

      $(this).parent().toggleClass('show-sub-menu');

      if (!$(this).parent().hasClass('has-back-btn')) {
        $(this).next('.sub-menu').prepend('<li class="sub-menu-parent">'+parentMenu+'</li>').prepend('<li class="sub-menu-back"><a href="#">Back</a></li>');
        $(this).parent().addClass('has-back-btn');
      }
    }
  });

  // Click function for inserted back button
  $('.site-header .main .navigation .sub-menu').on('click', '> .sub-menu-back', function(e) {
    e.preventDefault();
    $(this).parent().parent().toggleClass('show-sub-menu');
  });



  /* Popups */
  // Image popup
  // $('.open-image-link').magnificPopup({
  //   closeBtnInside: true,
  //   closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
  //   type: 'image'
  // });

  // Video popup
  // $('.open-video-link').magnificPopup({
  //   // closeBtnInside: true,
  //   closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
  //   preloader: false,
  //   removalDelay: 100,
  //   type: 'iframe',
  // });

  // Schedule Consultation popup
  // $('.schedule-consultation-form-link').magnificPopup({
  //   callbacks: {
  //     open: function() {
  //       // Add class to mfp-container element for css
  //       var popup = $('.schedule-consultation-popup');

  //       if (popup.length) {
  //         popup.parent().parent().addClass('mfp-schedule-consultation');
  //       }
  //     }
  //   },
  //   closeBtnInside: true,
  //   closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
  //   items: {
  //     src: '.schedule-consultation-popup',
  //   },
  //   mainClass: 'mfp-fade',
  //   removalDelay: 200,
  //   type: 'inline'
  // });



  /* Sliders */
  // Gallery slider
  // function gallerySlider() {
  //   $('.gallery-slider.acf-block .slides').each(function() {
  //     $(this).slick({
  //       appendArrows: $('.gallery-slider.acf-block .slider-controls > .arrows'),
  //       appendDots: $('.gallery-slider.acf-block .slider-controls > .dots'),
  //       arrows: false,
  //       // autoplay: true,
  //       autoplaySpeed: 5000,
  //       dots: true,
  //       // fade: true,
  //       infinite: true,
  //       mobileFirst: true,
  //       nextArrow: '<span class="icon-chevron-right icon" title="Next"></span>',
  //       prevArrow: '<span class="icon-chevron-left icon" title="Previous"></span>',
  //       responsive: [
  //         {
  //           breakpoint: breakpoints.small - 1,
  //           settings: {
  //             arrows: true,
  //             // dots: false,
  //           }
  //         }
  //       ],
  //       speed: 400
  //     });
  //   });
  // }
  // if ($('.gallery-slider.acf-block').length) {
  //   gallerySlider();
  // }
})(jQuery);
